import { impersonateLogout } from '@lessonup/client-integration';
import { AppName } from '@lessonup/config-environment-shared';
import {
  AssetLicenseStatusExpired,
  AssetLicenseStatusPro,
  AssetLicenseStatusSchool,
  breakpoints,
  Button,
  IconPageAssessments,
  Link,
  SetOpenHandle,
  spacing,
  styled,
  TopBar,
  TopBarItem,
  TopBarItemList,
  TopBarRight,
  TopBarVisibility,
} from '@lessonup/ui-components';
import { daysBetweenNowAndDate, Language } from '@lessonup/utils';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Avatar } from '../../components/Avatar/Avatar';
import { LicenseStatus } from '../../types/graphql';
import { channelsRoute, mySchoolRoute, searchRoute } from '../../utils/bridge/searchRoutes';
import {
  aiToolboxRoute,
  lessonEditorRoute,
  lessonEditorV2Route,
  lessonPlanRoute,
  myLessonsRoute,
  mySubscriptionRoute,
  teacherRoot,
} from '../../utils/bridge/teacherRoutes';
import { ActiveDocumentTab as ActiveDocumentTabType } from '../../utils/localStorage/useMyActiveDocumentTab';
import { MyLicense, MyUser } from './TeacherTopBar.graphql';
import {
  adminOrganizationIdsForUser,
  getCountryFilterForSearchTab,
  shouldHideLicenseIndicator,
} from './TeacherTopBar.utils';

const TRANSLATION_NS = 'topbar';

// right now we only support these active tabs
export type ActiveTab = 'explorer' | 'my-lesson' | 'ai-toolbox';

// hack so we do not have to update the packages for this temp fix
type AppNameOrTeacherNext = AppName | 'teacherNext';

export interface TeacherTopBarProps {
  user: MyUser | undefined;
  active?: ActiveTab;
  isLoggedIn: boolean;
  language: Language;
  app: AppNameOrTeacherNext;
  isImpersonating?: boolean;
  activeDocument?: ActiveDocumentTabType;
  showActiveTestIndicator?: boolean;
  onClickActiveTest?: () => void;
  onClickSignout?: () => void;
  showAiToolboxOverview?: boolean;
}

export const TeacherTopBar: React.FC<TeacherTopBarProps> = (props) => {
  const {
    user,
    showActiveTestIndicator,
    onClickActiveTest,
    language,
    isLoggedIn,
    isImpersonating,
    activeDocument,
    active,
    showAiToolboxOverview = false,
    app,
  } = props;

  const topBarRef = useRef<SetOpenHandle>(null);

  const hideLicenseIndicator = shouldHideLicenseIndicator(user);
  const adminOrganizationIds = adminOrganizationIdsForUser(user);

  const handleCloseTopBar = () => {
    topBarRef && topBarRef.current && topBarRef.current.setOpenValue(false);
    onClickActiveTest && onClickActiveTest();
  };

  return (
    <TopBar theme="secondary" logoUrl={teacherRoot.href({})} ref={topBarRef}>
      <ActiveTestIndicator showActiveTestIndicator={showActiveTestIndicator} onClickActiveTest={handleCloseTopBar} />
      <TopBarVisibility visibility="default">
        <TopBarItemList>
          <MyLessonUpTab active={active === 'explorer'} external={app !== 'teacher'} />
          <ActiveDocumentTab
            activeDocument={activeDocument}
            active={active === 'my-lesson'}
            external={app !== 'teacher'}
          />
          <SearchTab language={language} user={user} external={app !== 'search'} />
          <ChannelTab language={language} external={app !== 'search'} />
          <MySchoolTab language={language} user={user} external={app !== 'search'} />
          {showAiToolboxOverview && (
            <AiToolboxTab language={language} active={active === 'ai-toolbox'} external={app !== 'teacherNext'} />
          )}
        </TopBarItemList>
      </TopBarVisibility>
      <TopBarRight>
        <ImpersonatingButton isImpersonating={isImpersonating} />
        <LicenseUpsellButton hideLicenseIndicator={hideLicenseIndicator} license={user?.license} />
        <Avatar
          isLoggedIn={isLoggedIn}
          displayName={user?.name ?? undefined}
          onClickSignout={props.onClickSignout}
          adminOrganizationIds={adminOrganizationIds}
          showLinksAsExternal={app !== 'teacher'}
          licenseIndicator={
            <LicenseIndicator hideLicenseIndicator={hideLicenseIndicator} licenseStatus={user?.license?.status} />
          }
        />
      </TopBarRight>
    </TopBar>
  );
};

export const LicenseUpsellButton = ({
  license,
  hideLicenseIndicator,
}: {
  license?: MyLicense;
  hideLicenseIndicator: boolean;
}) => {
  const { t } = useTranslation(TRANSLATION_NS);
  if (!license) return null;
  const displayLicenseUpsellButton = ['EXPIRING', 'FREE'];
  if (hideLicenseIndicator || !displayLicenseUpsellButton.includes(license.status)) return null;

  // In some edge cases its possible for users to have an expiration date in the past
  // We want to show this users the upsell message for Free users.
  const daysRemainingForLicense = license.expiresAt ? daysBetweenNowAndDate(new Date(license.expiresAt)) : 0;
  const translationKey = daysRemainingForLicense >= 0 ? `licenseUpsell_${license.status}` : `licenseUpsell_${'FREE'}`;
  const upsellMessage = t(translationKey, { count: daysRemainingForLicense });

  return (
    <TopBarVisibility visibility="always-topbar">
      <Link href={mySubscriptionRoute.href({})} passHref>
        <Button data-testid="license-upsell-button" buttonType="secondary">
          {upsellMessage}
        </Button>
      </Link>
    </TopBarVisibility>
  );
};

export const ActiveTestIndicator = ({
  showActiveTestIndicator,
  onClickActiveTest,
}: Pick<TeacherTopBarProps, 'showActiveTestIndicator' | 'onClickActiveTest'>) => {
  if (!showActiveTestIndicator) return null;

  return (
    <TopBarVisibilityActiveTest visibility="always-topbar">
      <Button buttonShape="rect" buttonType="primary" onClick={onClickActiveTest} iconStart={<IconPageAssessments />} />
    </TopBarVisibilityActiveTest>
  );
};

const TopBarVisibilityActiveTest = styled(TopBarVisibility)`
  @media (min-width: ${breakpoints.topBarNavFullscreen}) {
    margin-right: ${spacing.size8};
  }
`;

export const LicenseIndicator: React.FC<{ licenseStatus?: LicenseStatus; hideLicenseIndicator?: boolean }> = ({
  hideLicenseIndicator,
  licenseStatus,
}) => {
  switch (true) {
    case licenseStatus === 'EXPIRED' && !hideLicenseIndicator:
      return <AssetLicenseStatusExpired />;
    case licenseStatus === 'PRO':
      return <AssetLicenseStatusPro />;
    case licenseStatus === 'SCHOOL':
      return <AssetLicenseStatusSchool />;
    default:
      return null;
  }
};

type LinkProps = {
  href: string;
  label: string;
  active?: boolean;
  external?: boolean;
};

const TopBarLink: React.FC<LinkProps> = ({ href, label, active, external }) => {
  return (
    <TopBarItem key={label} active={active}>
      <Link href={href} passHref external={external}>
        <a>{label}</a>
      </Link>
    </TopBarItem>
  );
};

const MyLessonUpTab = ({ active, external }: { active: boolean; external?: boolean }) => {
  const { t } = useTranslation(TRANSLATION_NS);
  return <TopBarLink label={t('myLessonUpTab')} href={myLessonsRoute.href({})} active={active} external={external} />;
};

const ActiveDocumentTab = ({
  activeDocument,
  active,
  external,
}: {
  activeDocument?: ActiveDocumentTabType;
  active: boolean;
  external?: boolean;
}) => {
  const { t } = useTranslation(TRANSLATION_NS);

  if (!activeDocument) return null;

  const translationKey =
    activeDocument.type === 'lesson' || activeDocument.type === 'lessonV2' ? 'myLessonTab' : 'myLessonPlanTab';

  const href = (() => {
    switch (activeDocument.type) {
      case 'lesson':
        return lessonEditorRoute.href({ lessonId: activeDocument.id, pinId: activeDocument.pinId });
      case 'lessonV2':
        return lessonEditorV2Route.href({ lessonId: activeDocument.id, pinId: activeDocument.pinId });
      case 'plan':
        return lessonPlanRoute.href({ planId: activeDocument.id, view: false });
    }
  })();

  return <TopBarLink label={t(translationKey)} href={href} active={active} external={external} />;
};

const SearchTab = ({
  language,
  user,
  external,
}: {
  language: Language;
  user: MyUser | undefined;
  external?: boolean;
}) => {
  const { t } = useTranslation(TRANSLATION_NS);
  const country = getCountryFilterForSearchTab(user, language);
  return <TopBarLink label={t('searchTab')} href={searchRoute.href({ language, country })} external={external} />;
};

const ChannelTab = ({ language, external }: { language: Language; external?: boolean }) => {
  const { t } = useTranslation(TRANSLATION_NS);
  return <TopBarLink label={t('channelTab')} href={channelsRoute.href({ language })} external={external} />;
};

const MySchoolTab = ({
  user,
  language,
  external,
}: {
  user: MyUser | undefined;
  language: Language;
  external?: boolean;
}) => {
  const { t } = useTranslation(TRANSLATION_NS);

  const schoolChannelSlug = user?.memberOfOrganizations.edges.find(
    (edge) => edge.node?.role === 'SCHOOL' && edge.node?.channel?.slug
  )?.node?.channel?.slug;

  if (!schoolChannelSlug) return null;
  return (
    <TopBarLink
      label={t('mySchoolTab')}
      href={mySchoolRoute.href({ language, schoolChannelSlug })}
      external={external}
    />
  );
};

const AiToolboxTab = ({ active, language, external }: { active: boolean; language: Language; external?: boolean }) => {
  const { t } = useTranslation(TRANSLATION_NS);
  return (
    <TopBarLink label={t('aiToolsTab')} href={aiToolboxRoute.href({ language })} active={active} external={external} />
  );
};

const ImpersonatingButton = ({ isImpersonating }: Pick<TeacherTopBarProps, 'isImpersonating'>) => {
  const { t } = useTranslation(TRANSLATION_NS);

  const stopImpersonate = () => {
    impersonateLogout();
    location.reload();
  };

  if (!isImpersonating) return null;

  return (
    <TopBarVisibility visibility="default">
      <Button buttonType="negative" onClick={stopImpersonate}>
        {t('stopWimpersonateButton')}
      </Button>
    </TopBarVisibility>
  );
};
